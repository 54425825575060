<template>
  <b-container>
    <!-- header -->
    <b-row>
      <b-col md="12">
        <h3 class="mb-1">
          Verify
        </h3>
        <b-card class="mb-2">
          <b-card-body>
            Please check your email for the verification code we sent you.
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- alerts -->
    <b-row
      v-if="verificationMessage"
      class="text-center"
    >
      <b-col md="12">
        <b-alert
          :variant="verificationType"
          show
        >
          <div class="alert-body">
            {{ verificationMessage }}
          </div>
        </b-alert>
      </b-col>
      <b-col
        v-if="verifySuccess"
        md="12"
      >
        <b-button
          variant="primary"
          :to="'/home'"
        >
          LOG IN TO PLAY
        </b-button>
      </b-col>
    </b-row>

    <!-- verification form -->
    <b-form-row
      v-if="!verifySuccess"
      class="mt-2"
    >
      <b-col
        cols="12"
        class="form-group"
      >
        <label class="text-success">Enter Code</label>
        <input
          v-model.trim="verifyCode"
          type="text"
          class="form-control"
          placeholder="Verification Code"
        >
      </b-col>
      <b-col
        cols="12"
        class="form-group text-right"
      >
        <b-button
          type="button"
          variant="primary"
          @click="verify"
        >
          Submit
        </b-button>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BFormRow, BCol, BCard, BCardBody, BButton, BAlert,
} from 'bootstrap-vue'
// import { useGtm } from '@gtm-support/vue2-gtm'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import IndexBackground from '@/assets/images/backgrounds/index.png'

export default {
  components: {
    BContainer,
    BRow,
    BFormRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      verifyCode: '',
      verificationMessage: null,
      verificationType: 'warning',
      verifySuccess: false,
    }
  },
  created() {
    document.body.style.backgroundImage = `url(${IndexBackground})`
    document.body.style.backgroundSize = 'cover'
  },
  mounted() {
    /* const canVerify = JSON.parse(localStorage.getItem('canVerify'))
    console.log('canVerify', canVerify)
    if (canVerify === null || canVerify === false) {
      this.$router.push('/')
    } */

    // parse the url to get the code
    const urlParams = new URLSearchParams(window.location.search)
    const vCode = urlParams.get('v')
    if (vCode) {
      this.verifyCode = vCode
    }
  },
  methods: {
    verify() {
      if (this.verifyCode === '') {
        this.showNotification('Please enter verification code')
        return
      }
      useJwt.verify(this.verifyCode)
        .then(response => {
          /* localStorage.removeItem('canVerify') */
          const verifyData = response.data.data
          console.log('verifyData', verifyData)
          this.verifySuccess = true
          // set persisted flag to indicate new user, for thank you message on homepage after redirected login
          // localStorage.setItem('newUser', true)
          // localStorage.setItem('registrationEventWasFired', false)

          this.fireRegistrationGTMEvent(verifyData)

          this.$router.push('/thank-you')
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Your email has now been verified.',
                  icon: 'BellIcon',
                  variant: 'success',
                  timeout: false,
                },
              }, {
                position: 'top-center',
              })
            })
        })
        .catch(error => {
          console.log(error.response.data)
          const errorResponse = error.response.data
          this.showNotification(errorResponse.data, 'warning')
        })
    },
    showNotification(message, type) {
      this.verificationMessage = message
      this.verificationType = type

      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'BellIcon',
          variant: type || 'warning',
        },
      }, {
        position: 'top-center',
      })
    },

    /* GTM Events */

    fireRegistrationGTMEvent(verifyData) {
      // use local storage to persist this flag across page refreshes
      // const regEventFired = localStorage.getItem('registrationEventWasFired')

      // on login the user information is stored - get it here for addition of user ID to GTM event
      const userData = verifyData || JSON.parse(localStorage.getItem('userData'))
      console.log('userData', userData)

      console.log('GTM registration event firing')
      // fire the GTM new user registration event
      // const gtm = useGtm()
      this.$gtm.trackEvent({
        event: 'registration' || 'interaction',
        category: 'user',
        action: 'registration',
        label: 'New DSP user registration',
        value: userData ? userData.email || userData.mobile : '',
      })

      /*
      // send the GTM event once
      if (!regEventFired) {
        // code to fire gtm event
        console.log('GTM event success')

        // set the persisted flag to indicate GTM event was fired
        localStorage.setItem('registrationEventWasFired', true)
      } else {
        // GTM event already fired
        console.log('GTM already fired')
      } */
    },
  },
}
</script>

<style>
</style>
